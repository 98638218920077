import { render, staticRenderFns } from "./TheHeader.vue?vue&type=template&id=2d9e7306&"
import script from "./TheHeader.vue?vue&type=script&lang=ts&"
export * from "./TheHeader.vue?vue&type=script&lang=ts&"
import style0 from "./TheHeader.vue?vue&type=style&index=0&id=2d9e7306&prod&lang=css&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseIcon: require('/home/caramaps/site/pro/www2/releases/20240823081932/components/base/BaseIcon.vue').default,DropdownHeaderLink: require('/home/caramaps/site/pro/www2/releases/20240823081932/components/DropdownHeaderLink.vue').default,LangSwitcher: require('/home/caramaps/site/pro/www2/releases/20240823081932/components/LangSwitcher.vue').default,SubHeader: require('/home/caramaps/site/pro/www2/releases/20240823081932/components/SubHeader.vue').default,DrawerLeft: require('/home/caramaps/site/pro/www2/releases/20240823081932/components/DrawerLeft.vue').default,DrawerRight: require('/home/caramaps/site/pro/www2/releases/20240823081932/components/DrawerRight.vue').default})
