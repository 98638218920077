
import { Component } from 'nuxt-property-decorator';

import MetadatasMixin from '~/ts/mixins/metadata';
import BaseToast from '~/components/base/BaseToast.vue';
import TheHeader from '~/components/TheHeader.vue';

@Component({
  components: { BaseToast, TheHeader },
})
export default class Default extends MetadatasMixin {}
