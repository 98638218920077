
import { Component } from 'nuxt-property-decorator';

import MetadatasMixin from '~/ts/mixins/metadata';
import BaseToast from '~/components/base/BaseToast.vue';
import CarouselGuest from '~/components/CarouselGuest.vue';

@Component({
  auth: false,
  components: { BaseToast, CarouselGuest },
})
export default class LoginLayout extends MetadatasMixin {}
